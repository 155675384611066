import React from 'react';
import Login from "./views/login";


function App() {
  return (
    <div id="index">
      <Login />
    </div>
  );
}

export default App;