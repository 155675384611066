import axios from "axios";
import querystring from "querystring";

export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';


export const login = credentials => {

    let body = {
        "grant_type": "password",
        "username": credentials.email,
        "password": credentials.password
    }

    let _data = querystring.stringify(body)

    return dispatch => {
        dispatch({ type: LOGIN_START})
        axios({
            url: 'https://rentreadymanager.azurewebsites.net/token',
            method: "POST",
            headers: {
                "Content-Type" : "application/x-www-form-urlencoded"
            },
            data: _data
        }).then(res => {
            console.log(res);
            dispatch({
                type: LOGIN_SUCCESS,
                payload: res.data
            })
        }).catch(err => {
            console.log(err);
            dispatch({
                type: LOGIN_FAILURE,
                payload: err
            })
        })
    }
}