import * as actionTypes from "../actions";

const initialState = {
    user: null,
    fetchingUser: false,
    error: null
}

export const loginReducer = (state = initialState, action) => {
    switch(action.type) {

        case actionTypes.LOGIN_START: {
            return {
                ...state,
                fetchingUser: !state.fetchingUser
            }
        }

        case actionTypes.LOGIN_SUCCESS: {
            return {
                ...state,
                fetchingUser: false,
                user: action.payload
            }
        }

        case actionTypes.LOGIN_FAILURE: {
            return {
                ...state,
                fetchingUser: false,
                error: action.payload
            }
        }

        default:
            return state;
    }
}