import React, { Component } from 'react';
import { connect } from "react-redux";
import { login } from "../actions";
import AnchorTag from "../components/anchor-tag/anchor-tag";
import Copyright from "../components/copyright/copyright";
import { Button as UIButton, Icon, Checkbox, Form, Input } from 'semantic-ui-react';

class Login extends Component {
    constructor() {
        super();
        this.state = {
            email: "",
            password: "",
            keepMeLoggedIn: false,
            showPassword: false,
            mobile: undefined
        }
    } 


    // adding resize event listener
    UNSAFE_componentWillMount = () => {

        // checks starting width of window
        if (window.innerWidth <= 1024) {
            this.setState({ mobile: true })
        } else {
            this.setState({ mobile: false })
        }

        // updates component based off of window resizing
        window.addEventListener('resize', ()=> {
            if (window.innerWidth <= 1024) {
                this.setState({ mobile: true })
            } else {
                this.setState({ mobile: false })
            }
        })
    }

    handleInputChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.login(this.state);
    }

    togglekeepMeLoggedIn = () => {
        this.setState({ keepMeLoggedIn: !this.state.keepMeLoggedIn });
    }

    togglePasswordView = () => {
        this.setState({ showPassword: !this.state.showPassword });
    }

    render() {

        return (
            <div className="login" id="login">
                <div className="login__logo-container">
                    <img className="logo" src="./img/login/RRM-Logo.png" alt="RRM Logo" />
                </div>

                <div className="login__left-container">
                    <div className="login__form-container">

                        <h2 className="login__form-title">Welcome Back</h2>


                        <Form className="form u-margin-top-30px-15px" onSubmit={this.handleSubmit}>
                            <Form.Field>
                                <label className="form__label form__label--big">Email Address</label>

                                <Input
                                    className='form__input form__input--big'
                                    type='email'
                                    placeholder='Email Address'
                                    name="email"
                                    value={this.state.email}
                                    onChange={this.handleInputChange}
                                    required
                                />
                            </Form.Field>

                            <Form.Field className="u-margin-bottom-0px">
                                <label className="form__label form__label--big">Password</label>

                                <Input
                                    placeholder='Password'
                                    icon={this.state.showPassword ? <Icon name='eye' link onClick={this.togglePasswordView} /> : <Icon name='eye slash' link onClick={this.togglePasswordView} />}
                                    type={this.state.showPassword ? "text" : "password"}
                                    className='form__input form__input--big'
                                    name="password"
                                    value={this.state.password}
                                    onChange={this.handleInputChange}
                                    required
                                />
                            </Form.Field>

                            <div className="form__actions">
                                <Form.Field className="form__field u-margin-top-handhelds-24px">
                                    <Checkbox onClick={this.togglekeepMeLoggedIn} className="form__checkbox form__checkbox--big form__checkbox--light" label='Keep me logged in' />

                                    <AnchorTag className="form__anchor-tag form__anchor-tag--big form__anchor-tag--light" href="/" text={"Forgot your password?"} />
                                </Form.Field>

                                <UIButton loading={this.props.fetchingUser} animated className="form__button form__button--big form__button--light u-margin-bottom-20px u-margin-top-37px-14px-20px-28px" onClick={this.handleSubmit}>
                                    <UIButton.Content visible={!this.state.mobile}>Log in {this.state.mobile ? <Icon name='arrow right' /> : null}</UIButton.Content>

                                    {this.state.mobile ? null : <UIButton.Content hidden> <Icon name='arrow right' /> </UIButton.Content>}
                                </UIButton>
                            </div>
                        </Form>
                    </div>
                </div>



                <div className="login__right-container">

                <svg width="68px" id="desktop_curve-xl" height="1080px" viewBox="0 0 68 1080" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                    <title>Curve-Desktop-XL</title>
                    <path d="M0,0 L13.1204997,0 C49.7068332,169.40954 68,349.40954 68,540 C68,730.59046 49.7068332,910.59046 13.1204997,1080 L0,1080 L0,0 Z" id="Background" fill="#FFFFFF"></path>
                </svg>

                <svg width="53px" id="desktop_curve-large" height="960px" viewBox="0 0 53 960" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                    <title>Curve-Desktop-Lg</title>
                    <path d="M0,0 L5.96068186,0 C37.3202273,157.681509 53,317.681509 53,480 C53,642.318491 37.3202273,802.318491 5.96068186,960 L0,960 L0,0 Z" id="Background" fill="#FFFFFF"></path>
                </svg>

                <svg width="40px" id="desktop_curve-medium" height="800px" viewBox="0 0 40 800" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                    <title>Curve-Desktop-Md</title>
                    <path d="M0,0 L7.85266719,0 C29.2842224,138.02452 40,271.357853 40,400 C40,528.642147 29.2842224,661.97548 7.85266719,800 L0,800 L0,0 Z" id="Background" fill="#FFFFFF"></path>
                </svg>

                    <svg width="37px" id="desktop_curve-small" height="768px" viewBox="0 0 37 768" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                        <title>Header-Regular-Desktop-Sm</title>
                        <path d="M0,0 L2.96325535,0 C25.6544185,119.558341 37,247.558341 37,384 C37,520.441659 25.6544185,648.441659 2.96325535,768 L0,768 L0,0 Z" id="Background" fill="#FFFFFF"></path>
                    </svg>

                    <svg width="834px" id="tablet_curve-big" height="47px" viewBox="0 0 834 47" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                        <title>Curve-Tablet-Lg</title>
                        <path d="M0,7.91879354 C153.479434,33.9729312 292.479434,47 417,47 C541.520566,47 680.520566,33.9729312 834,7.91879354 L834,0 L0,0 L0,7.91879354 Z" id="Background" fill="#FFFFFF"></path>
                    </svg>

                    <svg width="768px" id="tablet_curve-small" height="48px" viewBox="0 0 768 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                        <title>Curve-Tablet-Sm</title>
                        <path d="M0,7.96284002 C137.912577,34.65428 265.912577,48 384,48 C502.087423,48 630.087423,34.65428 768,7.96284002 L768,0 L0,0 L0,7.96284002 Z" id="Background" fill="#FFFFFF"></path>
                    </svg>

                    <svg width="320px" id="phone_curve" height="14px" viewBox="0 0 320 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                        <title>Curve-Phone320</title>
                        <path d="M0,2.32918811 C49.0313026,10.1097294 102.364636,14 160,14 C217.635364,14 270.968697,10.1097294 320,2.32918811 L320,0 L0,0 L0,2.32918811 Z" id="Background" fill="#FFFFFF"></path>
                    </svg>

                    <div className="login__mission-statement-container">
                        <h3 className="mission-statement u-margin-bottom-10px-13px">Expedite Unit Prep Time <br /> with Good Communication</h3>

                        <button className="btn-inverted btn-inverted--light ui button" >LEARN MORE</ button>
                    </div>

                    <div className="login__copyright-container">
                        <Copyright className="copyright copyright--light" />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        login: state.login,
        fetchingUser: state.loginReducer.fetchingUser,
        error: state.loginReducer.error
    };
};

export default connect(mapStateToProps, { login })(Login);